import React from 'react';
import {View} from 'react-native';
import Logo from '../../Components/Logo';
import {
  Container,
  Text,
  Title,
  Subtitle,
  SmallSubtitle,
  ContainerSocialNetworks,
  SponsorContainer,
  SponsorImage,
  FooterContainer,
} from './styles';

import {useSelector} from 'react-redux';
import instagram from '../../Assets/Images/instagramIcon.png';
import facebook from '../../Assets/Images/facebookIcon.png';
import SocialNetwork from '../SocialNetwork';
import {Linking, Platform} from 'react-native';
import {memo} from 'react';

const FooterHome = props => {
  // const {store} = useSelector((store)=> i);
  const {store} = useSelector(state => state.general);

  const openUrl = url => {
    if (Platform.OS === 'web') {
      window.open(url);
    } else {
      Linking.openURL(url);
    }
  };

  return (
    <FooterContainer>
      <Container>
        <Title>Mais Informações</Title>

        {store?.address && (
          <>
            <Subtitle>Localização</Subtitle>
            <Text>{store?.address}</Text>
          </>
        )}
        {(store?.email || store?.website) && (
          <>
            <Subtitle>Contatos</Subtitle>
            {store?.email && <Text>{store?.email}</Text>}
            {store?.website && <Text>{store?.website}</Text>}
          </>
        )}

        {store?.openings[0] && (
          <>
            <Subtitle>Horário</Subtitle>
            {store.openings.map(item => (
              <View
                key={`${item.title} ${item.openTime}`}
                style={{alignItems: 'center'}}>
                <SmallSubtitle>{item.title}</SmallSubtitle>
                <Text>
                  {item.openTime} -{' '}
                  {item.lunchStartTime &&
                    item.lunchStartTime + '  / ' + item.lunchEndTime + ' - '}
                  {item.closeTime}
                </Text>
              </View>
            ))}
          </>
        )}

        {/* {(store?.facebook || store?.instagram) && (
          <>
            <Subtitle>Redes Sociais</Subtitle>
            <ContainerSocialNetworks>
              {store?.facebook && (
                <SocialNetwork
                  type={'facebook'}
                  value={store?.facebook}
                  icon={facebook}
                />
              )}
              {store?.instagram && (
                <SocialNetwork
                  type={'instagram'}
                  value={store?.instagram}
                  icon={instagram}
                />
              )}
            </ContainerSocialNetworks>
          </>
        )} */}
        <SponsorContainer
          onPress={() =>
            openUrl(
              `https://menulegal.app?utm_source=${
                store?.name
              }&utm_medium=app_logo_footer&utm_campaign=client`,
            )
          }>
          <Logo />
        </SponsorContainer>
      </Container>
    </FooterContainer>
  );
};

export default memo(FooterHome);
