import styled from 'styled-components/native';
import style, {hpx} from '../../styles';

export const Container = styled.View`
  background: ${props => props.theme.colors.primary};
  align-items: center;
  padding: ${hpx(20)};
`;
export const Title = styled.Text`
  color: ${props => props.theme.colors.secondary};
  font-size: ${style.fonts.xlarge};
  font-family: ${style.fontName.ubuntuBold};

  padding-bottom: ${hpx(10)};
`;
export const Subtitle = styled.Text`
  color: ${props => props.theme.colors.secondary};
  font-size: ${style.fonts.medium};
  margin-vertical: ${hpx(10)};
  font-family: ${style.fontName.ubuntuBold};
`;
export const Text = styled.Text`
  color: ${props => props.theme.colors.secondary};
  font-size: ${style.fonts.xsmall};
  padding-bottom: ${hpx(5)};
  font-family: ${style.fontName.ubuntuRegular};
`;

export const SmallSubtitle = styled.Text`
  color: ${props => props.theme.colors.secondary};
  font-size: ${style.fonts.xsmall};
  margin-bottom: ${hpx(10)};
  font-family: ${style.fontName.ubuntuBold};
`;

export const ContainerSocialNetworks = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const SponsorContainer = styled.TouchableOpacity`
  align-items: center;
  padding: ${hpx(15)};
  height: 50px;
  margin-top: 15px;
  padding-bottom: 0px;
`;

export const SponsorImage = styled.Image`
  width: 195px;
  height: 55px;
`;
export const FooterContainer = styled.View`
  position: initial;
`;
