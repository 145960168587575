import React from 'react';
import {TouchableOpacity} from 'react-native';
import {useSelector} from 'react-redux';

import {Row, Col} from './styles.js';

const HeaderItem = ({Icon, children, noborder, onPress}) => {
  const {store} = useSelector(state => state.general);
  return (
    <TouchableOpacity onPress={onPress}>
      <Row noborder={noborder}>
        <Col>{Icon}</Col>
        <Col>{children}</Col>
      </Row>
    </TouchableOpacity>
  );
};

export default HeaderItem;
