import styled from 'styled-components/native';
import style from '../../../styles';

export const Row = styled.View`
  flex-direction: row;
  margin: 25px 5px 0;
  border-bottom-width: ${props => (props.noborder ? '' : '1px')};
  border-bottom-color: #eeeeee;
  padding-bottom: ${props => (props.noborder ? '' : '20px')};
`;
export const Col = styled.View`
  font-size: 14px;
  margin-right: 10px;
  font-family: ${style.fontName.ubuntuBold};
  color: ${props => props.theme.colors.black};
`;
export const Icon = styled.Image`
  width: 15px;
  height: 15px;
  resize-mode: contain;
`;
