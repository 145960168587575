import React from 'react';
import {ToastContainer, toast} from 'react-toastify';
import {Container, Logo, TextContainer, LogoText, Text, Title,LogoContainer} from './styles';
import bg from '../../Assets/Images/404bg.png';
import logo from '../../Assets/Images/logo-w.png';

const NotFound = () => {
  toast.info('Parece que esse menu não existe :(', {
    position: 'top-center',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    onClose: () => (window.location.href = 'https://menulegal.app/'),
  });

  return (
    <Container source={bg}>
      <ToastContainer />
      <LogoContainer>
        <Logo source={logo} />
        <LogoText>Ementa digital grátis</LogoText>
      </LogoContainer>

      <TextContainer>
        <Title>404</Title>
        <Text>Estabelecimento não encontrado </Text>
      </TextContainer>
    </Container>
  );
};

export default NotFound;
