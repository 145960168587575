import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {
  PriceContainer,
  PriceSelectorContainer,
  Label,
  PriceTitle,
  PriceRadio,
} from './styles';
import {formatCurrency} from './../../Helpers/currency';

const PriceSelector = ({prices, onChange}) => {
  const {store} = useSelector(state => state.general);
  const [selectedId, setSelectedId] = useState(prices[0].id);

  useEffect(() => {
    onChange(prices.filter(i => i.id === selectedId)[0]);
  }, [prices, selectedId, onChange]);

  return (
    <PriceSelectorContainer>
      <>
        {store.hasWhatsappDelivery && <Label>Selecione uma opção</Label>}
        {prices.map(i => (
          <PriceContainer key={i.id} onPress={() => setSelectedId(i.id)}>
            <PriceTitle>
              {i.title} {formatCurrency(i.value, store.currency)}
            </PriceTitle>
            {store.hasWhatsappDelivery && (
              <PriceRadio selected={i.id === selectedId} />
            )}
          </PriceContainer>
        ))}
      </>
    </PriceSelectorContainer>
  );
};

export default PriceSelector;
