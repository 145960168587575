import React from 'react';
import {Container} from './styles';
import {useNavigation} from '@react-navigation/native';
import {Image} from 'react-native-web';
import back from '../../Assets/Images/back.svg';
import {useSelector} from 'react-redux';

const BackArrow = () => {
  const {store} = useSelector(state => state.general);
  const navigation = useNavigation();
  return (
    <Container onPress={() => navigation.goBack()}>
      <Image style={{flex: 1, tintColor: store.theme.primary}} source={back} />
    </Container>
  );
};

export default BackArrow;
