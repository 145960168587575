import * as storeAPI from './store';
import {REACT_APP_API_URL} from 'react-native-dotenv';

const domain = process.env.REACT_APP_API_URL ?? REACT_APP_API_URL ?? window.location.replace('www.', '');

export const API_URL = `${domain}/customer/public`;
export const defaultErrorHandler = async result => {
  const status = await result.status;
  const text = await result.text;
  console.warn('Request Error', status, text);
};

export {storeAPI};
