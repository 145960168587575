import {Dimensions} from 'react-native';

export const h = value => {
  return value * sizeMultiplier.height;
};

export const hpx = value => {
  return value * sizeMultiplier.height + 'px';
};
export const wpx = value => {
  return value * sizeMultiplier.width + 'px';
};
const sizeMultiplier = {
  height: Dimensions.get('window').height / 812,
  width: Dimensions.get('window').width / 375,
};

const colors = {
  primary: '#7159C1',
  secondary: '#fff',
  black: '#000',
  btnText: '#4B4B4B',
  btnBg: '#514089',
  backgroundGray: '#e3dddd',
  green: '#85C159',
  red: '#EB6464',
  gray: '#545454',
  white: '#fff',
  lightGray: '#CECECE',
  themeType: 'light-content',
};
const fonts = {
  xxsmall: hpx(12),
  xsmall: hpx(14),
  small: hpx(16),
  medium: hpx(18),
  large: hpx(24),
  xlarge: hpx(28),
  xxlarge: hpx(34),
  xxxlarge: hpx(50),
};
const fontName = {
  ubuntuRegular: 'CircularStdMedium',
  ubuntuBold: 'CircularStdBold',
};

const style = {
  colors,
  fonts,
  fontName,
};

export default style;
