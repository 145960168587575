import React from 'react';

import {Container, Name} from './styles';

const Category = ({active, onPress, children}) => {
  return (
    <Container onPress={onPress} active={active}>
      <Name active={active}>{children}</Name>
    </Container>
  );
};

export default Category;
