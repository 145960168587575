import styled from 'styled-components/native';
import style, {hpx} from '../../styles';

export const Container = styled.View`
  padding: 10px 20px;
  background: ${style.colors.white};
`;
export const ItemContainer = styled.TouchableOpacity`
  flex-direction: row;
  width: 100%;
  align-items: center;
  opacity: ${props => (props.disabled ? 0.4 : 1)};
`;
export const TextContainer = styled.View`
  flex: 1;
`;
export const CategoryName = styled.Text`
  color: ${props => props.theme.colors.primary};
  font-size: ${style.fonts.large};
  margin-bottom: ${hpx(15)};
  font-family: ${style.fontName.ubuntuBold};
`;
export const Title = styled.Text`
  color: ${style.colors.black};
  font-size: ${style.fonts.small};
  font-family: ${style.fontName.ubuntuRegular};
  line-height: 150%;
`;
export const Description = styled.Text`
  color: ${style.colors.gray};
  font-size: ${style.fonts.xsmall};
  padding-vertical: 5px;
  font-family: ${style.fontName.ubuntuRegular};
  line-height: 150%;
  margin-top:5px

`;
export const PricesContainer = styled.View``;
export const Prices = styled.Text`
  color: ${style.colors.black};
  font-size: ${style.fonts.small};
  font-family: ${style.fontName.ubuntuRegular};
  line-height: 150%;
  margin-top:5px

`;
export const ImageContainer = styled.View`
  padding-left: 10px;
`;
export const Image = styled.Image`
  width: 96px;
  height: 96px;
  border: 1px solid #DEDEDE;

`;

export const Separator = styled.View`
  height: 1px;
  border-width: 1px;
  border-color: #dedede;
  margin-top: 20px;
`;
export const FooterContainer = styled.View`
  position: initial;
`;
