// src/store/index.js
import {combineReducers} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import {createStore} from 'redux';
import storage from 'redux-persist/lib/storage';

import cart from './CartStore';
import general from './GeneralStore';
import loading from './LoadingStore';

const reducers = combineReducers({
  cart,
  general,
  loading,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
export const persistor = persistStore(store);
