import React from 'react';

export default function CoverImage({url}) {
  return (
    <img
      src={url}
      alt="cover"
      style={{objectFit: 'contain', width: '100%', maxHeight: 400}}
    />
  );
}
