import styled from 'styled-components/native';
import style, {hpx} from './../../styles/index';

export const Container = styled.View`
  margin-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const DataContainer = styled.View`
  margin-bottom: 25px;
`;

export const Category = styled.Text`
  color: ${props => props.theme.colors.primary};
  font-size: ${style.fonts.small};
  font-family: ${style.fontName.ubuntuBold};
  margin-bottom: ${hpx(10)};
`;

export const Title = styled.Text`
  font-size: ${style.fonts.large};
  font-family: ${style.fontName.ubuntuRegular};
  margin-bottom: ${hpx(10)};
  color: black;
`;

export const Description = styled.Text`
  font-family: ${style.fontName.ubuntuRegular};
  font-size: ${style.fonts.medium};
  color: ${style.colors.gray};
`;
