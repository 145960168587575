import React from 'react';

import { Container, Icon } from './styles';
import { openUrl } from '../../Helpers/general';

import { CreateEvent } from '../../Services/API/store';
import { EVENT_TYPE } from '../../Services/API/schemas';

const sanitizeSocialMediaName = (value) => {
  return value.replace('https://m.facebook.com/', '')
    .replace('https://facebook.com/', '')
    .replace('https://www.facebook.com/', '')
    .replace('@', '')
    .replace('https://instagram.com/', '')
    .replace('https://www.instagram.com/', '')
}

const SocialNetwork = ({ type, value, icon }) => {
  let finalUrl;
  let eventType;

  if (type === "facebook") {
    finalUrl = "https://www.facebook.com/";
    eventType = EVENT_TYPE.CLICK_FACEBOOK;
  } else if (type === "instagram") {
    finalUrl = "https://www.instagram.com/";
    eventType = EVENT_TYPE.CLICK_INSTAGRAM;
  }

  finalUrl += sanitizeSocialMediaName(value);

  const handlePress = () => {
    CreateEvent(eventType);
    openUrl(finalUrl);
  }

  return (
    <Container onPress={handlePress}>
      <Icon source={icon} />
    </Container>
  );
};

export default SocialNetwork;
