import React,{useEffect} from 'react';
import {useNavigation} from '@react-navigation/native';
import {
  Container,
  TextContainer,
  Title,
  Description,
  Prices,
  Image,
  CategoryName,
  ItemContainer,
  ImageContainer,
  Separator,
} from './styles';
import {useSelector} from 'react-redux';
import {formatCurrency} from '../../Helpers/currency';
import moment from 'moment';
const Item = ({item, firstItem}) => {
  const {store} = useSelector(state => state.general);
  const navigation = useNavigation();


  const Variation = ({item}) =>{
    if(item?.availableDays[moment().format('dddd').toLowerCase()]){
      if(item.isActive){
        return(<Prices>
          {item.prices.length > 1 && 'A partir de: '}
          {/* {item?.prices[0]?.name} */}
          {item?.prices[0]?.value &&
            formatCurrency(item?.prices[0]?.value, store?.currency)}
        </Prices>)
      }else{
        return (<Prices>Esgotado</Prices>)
      }
    }else{
      return (<Prices>Indisponível hoje</Prices>)
    }
    
  }
  

  return (
    <Container>
      {firstItem && <CategoryName>{item?.category.name}</CategoryName>}
      <ItemContainer
        disabled={!item.isActive || !item?.availableDays[moment().format('dddd').toLowerCase()] }
        onPress={() =>
          navigation.navigate('Description', {
            item,
          })
        }>
        <TextContainer>
          <Title>{item?.name}</Title>
          <Description>{item?.description}</Description>
          <Variation item={item}/>
        </TextContainer>
        <ImageContainer>
          {item?.photos[0]?.url ? (
            <Image
              source={{
                uri: item?.photos[0]?.thumbnailUrl ?? item?.photos[0]?.url,
              }}
            />
          ) : (
            <></>
          )}
        </ImageContainer>
      </ItemContainer>
      <Separator />
    </Container>
  );
};

export default Item;
