import React, {useState, useRef} from 'react';
import {FlatList, Text} from 'react-native';
import {sortBy, uniq} from 'lodash';
import Item from './Item';
import {useSelector} from 'react-redux';
import Categories from './../Categories/index';
import FooterHome from './../FooterHome/index';
import HeaderHome from './../HeaderHome/index';
import SectionSeparator from '../SectionSeparator/';
import CartButton from '../CartButton';
import {useNavigation} from '@react-navigation/native';
import {CreateEvent} from '../../Services/API/store';
import {EVENT_TYPE} from '../../Services/API/schemas';
const ItemList = () => {
  const cart = useSelector(state => state.cart);

  const {store} = useSelector(state => state.general);
  const flatlistRef = useRef();
  const changeCategoryDebounce = useRef();
  const viewConfigRef = React.useRef({viewAreaCoveragePercentThreshold: 50});
  const onViewRef = React.useRef(info => {
    const category = info.viewableItems[0]?.item?.category?.id;
    if (category) changeCategoryDelayed(category, false);
  });
  const navigation = useNavigation();

  const [data, setData] = useState([
    'menu',
    ...sortBy(store.products, item => {
      return 100 * item.category.order + (item.isActive ? 0 : 1);
    }),
  ]);
  const [searchableData, setSearchableData] = useState([
    'menu',
    ...sortBy(store.products, item => {
      return 100 * item.category.order + (item.isActive ? 0 : 1);
    }),
  ]);

  const getAllCategoryIndex = () => {
    const categories = uniq(
      searchableData.map(i => i.category?.id).filter(i => i),
    );
    let categorieIndexs = [];
    categories.forEach(element => {
      categorieIndexs.push(
        searchableData.findIndex(i => i.category?.id === element),
      );
    });
    return categorieIndexs;
  };
  const categoryIndexes = getAllCategoryIndex();

  const getCategoryIndex = category => {
    return searchableData.findIndex(i => i.category?.id === category);
  };

  const [activeCategory, setActiveCategory] = useState(1);

  const changeCategory = (category, makeScroll) => {
    if (makeScroll) {
      const index = getCategoryIndex(category);
      if (index >= 0) {
        flatlistRef.current.scrollToIndex({
          index: index,
          viewOffset: 50,
        });
        setTimeout(() => {
          clearTimeout(changeCategoryDebounce.current);
        }, 300);
      }
    }
    setActiveCategory(category);
  };

  const changeCategoryDelayed = category => {
    if (changeCategoryDebounce.current) {
      clearTimeout(changeCategoryDebounce.current);
      changeCategoryDebounce.current = null;
    }
    changeCategoryDebounce.current = setTimeout(() => {
      changeCategory(category, false);
    }, 500);
  };
  const handleSearch = item => {
    console.log(item);
    const actual = [...data];

    let result = actual.filter(it => {
      return it?.name
        ?.toUpperCase()
        .cleanText()
        ?.includes(item.toUpperCase().cleanText());
    });
    console.log(data, actual, result);
    getAllCategoryIndex();
    setSearchableData(['menu', ...result]);
  };

  return (
    <>
      <FlatList
        ref={flatlistRef}
        nestedScrollEnabled
        viewabilityConfig={viewConfigRef.current}
        stickyHeaderIndices={[1]}
        style={{flex: 1}}
        data={searchableData}
        ListHeaderComponent={<HeaderHome handleSearch={handleSearch} />}
        ListFooterComponent={<FooterHome />}
        renderItem={({item, index}) =>
          item === 'menu' ? (
            <Categories
              items={searchableData}
              activeCategory={activeCategory}
              onPressItem={item => {
                CreateEvent(EVENT_TYPE.CLICK_CATEGORY, item.id);
                changeCategory(item.id, true);
              }}
            />
          ) : (
            <Item item={item} firstItem={categoryIndexes.includes(index)} />
          )
        }
        keyExtractor={item => `${item.id}`}
        onViewableItemsChanged={onViewRef.current}
      />
      {//adicionar validacao se o cara tem a funcionalidade de delivery ativada
      store.hasWhatsappDelivery && cart?.items?.length > 0 && (
        <CartButton
          cart={cart}
          currency={store.currency}
          onPress={() => navigation.navigate('Cart')}>
          ver carrinho
        </CartButton>
      )}
    </>
  );
};

export default ItemList;
