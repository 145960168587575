import React, {useState} from 'react';
import {TouchableOpacity} from 'react-native';
import {useSelector} from 'react-redux';
import {formatCurrency} from './../../Helpers/currency';
import {
  Container,
  AddButton,
  QuantityContainer,
  MinusButtonText,
  PlusButtonText,
  ItemCount,
  AddButtonText,
} from './styles';

const ItemAddCart = ({price, onChange}) => {
  const {store} = useSelector(state => state.general);
  const [quantity, setQuantity] = useState(1);

  const disable = !price?.value;

  if (!store.hasWhatsappDelivery) return <></>;

  return (
    <Container>
      <QuantityContainer disabled={disable}>
        <TouchableOpacity
          disabled={disable}
          onPress={() => setQuantity(qun => (qun !== 1 ? qun - 1 : qun))}>
          <MinusButtonText>-</MinusButtonText>
        </TouchableOpacity>
        <ItemCount>{quantity}</ItemCount>
        <TouchableOpacity
          disabled={disable}
          onPress={() => setQuantity(qun => qun + 1)}>
          <PlusButtonText>+</PlusButtonText>
        </TouchableOpacity>
      </QuantityContainer>
      <AddButton disabled={disable} onPress={() => onChange(quantity)}>
        <AddButtonText>Adicionar</AddButtonText>
        <AddButtonText>
          {price?.value &&
            formatCurrency(price?.value * quantity, store.currency)}
        </AddButtonText>
      </AddButton>
    </Container>
  );
};

export default ItemAddCart;
