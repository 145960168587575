import styled from 'styled-components/native';
import style, {hpx} from './../../styles/index';

export const Container = styled.View`
  padding: ${hpx(20)} 0px;
  height: ${hpx(115)};
  display: flex;
  flex-direction: row;
`;

export const AddButton = styled.TouchableOpacity`
  opacity: ${props => (props.disabled ? 0.2 : 1)};

  background-color: ${props => props.theme.colors.primary};
  flex: 1;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

export const AddButtonText = styled.Text`
  font-family: ${style.fontName.ubuntuRegular};
  font-size: ${style.fonts.large};
  color: ${style.colors.white};
`;

export const QuantityContainer = styled.View`
  opacity: ${props => (props.disabled ? 0.2 : 1)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  border: solid 1px ${style.colors.gray};
  margin: 0px 5px;
`;

export const MinusButtonText = styled.Text`
  font-family: ${style.fontName.ubuntuRegular};
  font-size: ${style.fonts.xxxlarge};
  color: ${props => props.theme.colors.primary};
  padding: 10px;
`;

export const PlusButtonText = styled.Text`
  font-family: ${style.fontName.ubuntuRegular};
  font-size: ${style.fonts.xxlarge};
  color: ${props => props.theme.colors.primary};
  padding: 10px;
  margin-bottom: 3px;
`;

export const ItemCount = styled.Text`
  font-family: ${style.fontName.ubuntuRegular};
  font-size: ${style.fonts.large};
  color: ${style.colors.gray};
`;
