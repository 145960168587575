import styled from 'styled-components/native';
import style from '../../styles';
import {ReactComponent as SearchIcon} from '../../Assets/Images/search.svg';
export const Container = styled.View`
  background: ${style.colors.white};
`;
export const CoverContainer = styled.View`
  background-size: contain;
  div {
    background-size: contain;
  }
`;

export const Cover = styled.ImageBackground`
  width: 100%;
  height: 250px;

  background-size: contain;
  @media (max-width: 768px) {
    height: 90px;
  }
`;

export const PhoneContainer = styled.View`
  flex-direction: row;
  justify-content: space-around;
`;
export const PhoneNumberButton = styled.TouchableOpacity`
  background: ${props => props.theme.colors.primary};
  border-radius: 5px;
  padding: 10px;
  flex: 1;
  text-align: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-horizontal: 5px;
`;

export const PhoneNumber = styled.Text`
  color: ${props => props.theme.colors.secondary};
  border-radius: 10px;
  font-size: ${style.fonts.medium};
  font-family: ${style.fontName.ubuntuRegular};
`;

export const Address = styled.Text`
  font-size: ${style.fonts.medium};
  color: ${style.colors.gray};
`;
export const Name = styled.Text`
  font-family: ${style.fontName.ubuntuBold};
  color: ${props => props.theme.colors.primary};
  border-radius: 10px;
  font-size: 32px;
  margin-bottom: 5px;
`;

export const AddressContainer = styled.TouchableOpacity`
  flex-direction: row;
  padding-vertical: 15px;
  align-items: center;
  padding-right: 30px;
`;

export const AddressIcon = styled.Image`
  width: 25px;
  height: 15px;
  resize-mode: contain;
  margin-right: 5px;
`;
export const TextContainer = styled.View`
  padding: 20px;
  font-size: 14px;
  font-family: ${style.fontName.ubuntuRegular};
`;
export const IconImage = styled.Image`
  width: 20px;
  height: 20px;
  resize-mode: contain;
  margin-right: 10px;
`;
export const MapLink = styled.Text`
  text-decoration: underline;
  font-size: 14px;
`;

export const SearchContainer = styled.View`
  font-size: 14px;
  padding: 10px;
  border-width: 1px;
  border-color: #ccc;
  margin: 0 25px 15px;
  border-radius: 5px;
  padding-left: 10px;
  flex-direction: row;
`;
export const Search = styled.TextInput`
  width: 100%;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline-color: transparent;
  outline-style: none;
  color: #999;
`;

export const MoreInformationContainer = styled.View`
  font-size: 14px;
`;

export const MoreInformationHidden = styled.View``;
export const Description = styled.Text`
  color: #999;
  font-size: ${style.fonts.medium};
  font-family: ${style.fontName.ubuntuRegular};
  padding: 10px 0;
`;
