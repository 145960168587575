import styled from 'styled-components/native';

export const Container = styled.TouchableOpacity`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 2;
`;
