import React from 'react';
import {formatCurrency} from '../../../Helpers/currency';
import {useDispatch} from 'react-redux';
import {setProduct, removeProduct} from '../../../Store/CartStore';
import {useSelector} from 'react-redux';

import {
  Container,
  Name,
  PriceTitle,
  NameContainer,
  Price,
  Picker,
  PickerNameContainer,
  Separator,
} from './styles';

const CartItem = ({item}) => {
  const dispatch = useDispatch();
  const {store} = useSelector(state => state.general);

  const {name, price, quantity} = item;

  const handleChangeValue = value => {
    if (value === 'remove') {
      dispatch(removeProduct(item));
    } else dispatch(setProduct({...item, quantity: value}));
  };

  var pickerRows = [];
  for (var i = 1; i <= 99; i++) {
    pickerRows.push(
      <Picker.Item
        label={i.toString().length === 1 ? `0${i}` : i}
        value={i}
        style={{textAlign: 'center'}}
      />,
    );
  }
  return (
    <>
      <Container>
        <PickerNameContainer>
          <Picker value={quantity} onValueChange={handleChangeValue}>
            <Picker.Item label="Remover" value={'remove'} />
            {pickerRows}
          </Picker>

          <NameContainer>
            <Name numberOfLines={2}>{name}</Name>
            <PriceTitle>
              {price.title && price.title + ' - '}{' '}
              {formatCurrency(price.value, store?.currency)}
            </PriceTitle>
          </NameContainer>
        </PickerNameContainer>
        <Price>{formatCurrency(price.value * quantity, store?.currency)}</Price>
      </Container>
      <Separator />
    </>
  );
};

export default CartItem;
