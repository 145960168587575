import {API_URL, defaultErrorHandler} from '../API';

export const GetStoreInfo = async () => {
  const result = await fetch(`${API_URL}/store`);
  if (result.ok) {
    return await result.json();
  } else if (result.status === 404) {
    return 404;
  }
  defaultErrorHandler(result);
};

export const CreateEvent = async (type, referenceId) => {
  let userKey = localStorage.getItem('events-key');
  if (!userKey) {
    userKey = Date.now();
    localStorage.setItem('events-key', userKey);
  }

  fetch(`${API_URL}/event`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      type,
      referenceId,
      userKey,
    }),
  });
};

export const SendOrder = async (total, order, origin) => {
  let userKey = localStorage.getItem('events-key');
  if (!userKey) {
    userKey = Date.now();
    localStorage.setItem('events-key', userKey);
  }

  fetch(`${API_URL}/order`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      total,
      origin,
      order,
    }),
  });
};
