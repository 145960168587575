import React from 'react';
import {ThemeProvider as RNThemeProvider} from 'styled-components/native';
import {useSelector} from 'react-redux';

const ThemeProvider = ({children}) => {
  const general = useSelector(state => state.general);
  return (
    <RNThemeProvider theme={{colors: general.store.theme}}>
      {children}
    </RNThemeProvider>
  );
};

export default ThemeProvider;
