import React from 'react';
import {useSelector} from 'react-redux';

import ItemList from '../../Components/ItemList';
import {Container} from './styles';

const Home = ({navigation}) => {
  window.onhashchange = function() {
    navigation.goBack();
  };

  const general = useSelector(state => state.general);

  return (
    <Container>
      <ItemList />
    </Container>
  );
};

export default Home;
