export const formatCurrency = (valueIn, currency) => {
  let value = valueIn / 100;

  if (!currency) {
    return value;
  }

  if (['R$'].indexOf(currency) > -1) {
    return `${currency} ${value.toFixed(2).replace('.', ',')}`;
  }

  return `${value.toFixed(2)} ${currency}`;
};
