import React, {useState, useEffect} from 'react';
import ImageSlider from '../../Components/ImageSlider/index';
import {useRoute} from '@react-navigation/native';
import ItemDescription from './../../Components/ItemDescription/index';
import {Container, DataContainer} from './styles';
import ItemPricesSelector from './../../Components/ItemPricesSelector';
import BackArrow from '../../Components/BackArrow';
import ItemNotes from '../../Components/ItemNotes';
import ItemAddCart from '../../Components/ItemAddCart';
import {setProduct} from '../../Store/CartStore';
import {useDispatch} from 'react-redux';
import {ToastContainer, toast} from 'react-toastify';

import {CreateEvent} from '../../Services/API/store';
import {EVENT_TYPE} from '../../Services/API/schemas';

const Description = ({navigation}) => {
  const dispatch = useDispatch();

  const route = useRoute();
  const item = route.params.item;
  const [selectedPrice, setSelectedPrice] = useState(
    item?.prices && item.prices[0],
  );

  const [note, setNote] = useState('');

  useEffect(() => {
    window.history.pushState('product', 'produto', '#product');

    CreateEvent(EVENT_TYPE.VIEW_PRODUCT, item.id);
  }, []);

  return (
    <>
      <ToastContainer />
      <BackArrow />
      <Container>
        <DataContainer>
          <ImageSlider photos={item.photos.map(photo => photo.url)} />
          <ItemDescription item={item} />
          {item?.prices?.length > 0 && (
            <ItemPricesSelector
              onChange={price => setSelectedPrice(price)}
              prices={item.prices}
            />
          )}
          <ItemNotes onChange={note => setNote(note)} />
        </DataContainer>

        <ItemAddCart
          onChange={quantity => {
            dispatch(
              setProduct({
                id: item.id,
                name: item.name,
                price: selectedPrice,
                quantity,
                note,
              }),
            );

            CreateEvent(EVENT_TYPE.ADD_TO_CART, item.id);

            toast.success('Produto adicionado!', {
              position: 'top-center',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              onClose: () => navigation.goBack(),
            });
          }}
          price={selectedPrice}
        />
      </Container>
    </>
  );
};

export default Description;
