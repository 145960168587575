import styled from 'styled-components/native';
import style  from './../../styles/index';

export const Container = styled.View`
  padding: 20px;
`;

export const Text = styled.TextInput`
  margin-top: 10px;
  padding: 10px 15px;
  border: solid 1px black;
  font-family: ${style.fontName.ubuntuRegular};
  font-size: ${style.fonts.medium};
  height: 200px;
`;

export const Label = styled.Text`
  font-family: ${style.fontName.ubuntuRegular};
  font-size: ${style.fonts.medium};
  color: ${style.colors.gray};
`;
