import styled from 'styled-components/native';
import style, {hpx} from '../../styles';
export const Container = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 20px;
  background: ${style.colors.white};
`;

export const Line = styled.View`
  background: ${style.colors.lightGray};
  width: 100%;
  height: ${hpx(2)};
  position: relative;
  flex: 1;
`;

export const Icon = styled.Image`
  width: 40px;
  height: 40px;
`;
export const Point = styled.View`
  background: ${style.colors.lightGray};
  width: ${hpx(12)};
  height: ${hpx(12)};
  border-radius: 12;
  margin: 0 10px;
`;
