import React, {useState} from 'react';
import {View} from 'react-native';
import Modal from 'react-modal';
import {
  Container,
  Title,
  HeaderCart,
  ItemsContainer,
  ButtonContainer,
  TotalPrice,
  TotalContainer,
  TotalTitle,
  InputText,
  InputTextContainer,
  ConfirmContainer,
  CloseButton,
} from './styles';
import {reset, setClientName, setAddress} from '../../../Store/CartStore';
import {formatCurrency} from '../../../Helpers/currency';
import CustomButton from '../../../Components/CustomButton';
import {ReactComponent as CloseIcon} from '../../../Assets/Images/close.svg';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigation} from '@react-navigation/native';
import {CreateEvent, SendOrder} from '../../../Services/API/store';
import {EVENT_TYPE} from '../../../Services/API/schemas';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {openUrl} from '../../../Helpers/general';

const Checkout = ({clientModal, setClientModal}) => {
  const cart = useSelector(state => state.cart);
  const {payment} = cart;
  const {store} = useSelector(state => state.general);
  const [modalClientName, setModalClientName] = useState(cart.clientName);
  const [modalClientAddress, setModalClientAddress] = useState(cart.address);
  const [modalClientChange, setModalClientChange] = useState(0);
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const getOrderText = () => {
    dispatch(setAddress(modalClientAddress));
    dispatch(setClientName(modalClientName));

    if (cart?.items?.length <= 0) {
      toast.error('Você não tem itens no carrinho', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    var message = 'Pedido realizado utilizando Menu Legal \r\n\r\n';
    message += `Cliente: *${modalClientName}*\r\nEndereço de entrega: *${modalClientAddress}*\r\n\r\n`;

    cart.items.forEach(({quantity, name, price, note}) => {
      message += `${quantity}x ${name ? name : ''}${
        price.title ? price.title : ''
      } *${formatCurrency(price.value * quantity, store?.currency)}*${
        note ? '\r\n' + note : ''
      }\r\n\r\n`;
    });

    message += `\r\n\r\nTipo de entrega: *${cart.delivery.name}*`;

    message += `\r\nValor da entrega: *${cart.delivery.display}*`;

    if (store.hasPayment && store.payments.length > 0 && cart.payment) {
      message += `\r\n\r\nForma de pagamento: *${cart.payment.name}*\r\n`;
    }

    message += `Itens: *${formatCurrency(cart.totalItems, store?.currency)}*`;
    message += ` \r\nTOTAL: *${formatCurrency(
      cart.total,
      store?.currency,
    )}*\r\n`;

    if (payment?.hasChange) {
      if (modalClientChange > 0 && modalClientChange < cart.total) {
        toast.error(
          'O valor que irá pagar tem que ser maior que o total para ter troco',
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
        return;
      }

      if (modalClientChange > 0 && modalClientChange != cart.total) {
        message += `\r\n*Troco*`;
        message += `\r\nValor que será pago: *${formatCurrency(
          modalClientChange,
          store?.currency,
        )}*`;

        message += `\r\nValor do troco: *${formatCurrency(
          modalClientChange - cart.total,
          store?.currency,
        )}*`;
      }
    }

    CreateEvent(EVENT_TYPE.ORDER);

    SendOrder(cart.total, cart, 'whatsapp');

    cart.items.forEach(element => {
      CreateEvent(EVENT_TYPE.BUY_PRODUCT, element.id);
    });

    dispatch(reset());
    openUrl(`https://wa.me/${store.whatsapp}?text=${encodeURI(message)}`);
    toast.success('Pedido encaminhado para o WhatsApp! Bom apetite!', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: () => {
        navigation.goBack();
      },
    });
  };

  return (
    <Modal
      isOpen={clientModal}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      }}>
      <View style={{maxWidth: 400}}>
        {
          <ConfirmContainer>
            <CloseButton onPress={() => setClientModal(false)}>
              <CloseIcon fill="#000" style={{height: 20}} />
            </CloseButton>
            <InputTextContainer>
              <label>Seu nome</label>
              <InputText
                placeholder="Digite seu nome"
                name="name"
                defaultValue={cart.clientName}
                onChangeText={setModalClientName}
              />
            </InputTextContainer>
            <InputTextContainer>
              <label>Endereço completo</label>
              <InputText
                placeholder="Digite seu endereço "
                name="address"
                defaultValue={cart.address}
                onChangeText={setModalClientAddress}
              />
            </InputTextContainer>
            {store.hasPayment && payment?.hasChange && (
              <InputTextContainer>
                <label>Precisa de troco para quanto?</label>
                <InputText
                  placeholder={`Caso não precise, deixe em branco :)`}
                  keyboardType="numeric"
                  name="change"
                  onChangeText={value => setModalClientChange(value * 100)}
                />
              </InputTextContainer>
            )}
            <CustomButton
              accessibilityRole="link"
              target="_blank"
              onPress={() => getOrderText()}>
              Confirmar Pedido
            </CustomButton>
          </ConfirmContainer>
        }
      </View>
    </Modal>
  );
};

export default Checkout;
