import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {setDelivery} from '../../../Store/CartStore';

import {Container, PriceRadio, Label} from './styles';

const DeliveryOption = ({data}) => {
  const {name, id, value, display} = data;
  const {delivery} = useSelector(state => state.cart);
  const dispatch = useDispatch();

  const handleDeliverySelect = () => {
    dispatch(setDelivery(data));
  };
  return (
    <TouchableOpacity onPress={handleDeliverySelect}>
      <Container>
        <Label>
          {name} ({display})
        </Label>
        <PriceRadio selected={delivery.id === id} />
      </Container>
    </TouchableOpacity>
  );
};

export default DeliveryOption;
