import styled from 'styled-components/native';
import style from '../../../styles/index';
export const ItemsContainer = styled.ScrollView`
  background: ${style.colors.white};
  flex: 1;
`;
export const Container = styled.View`
  background: ${style.colors.white};
  flex: 1;
  padding: 20px;
`;

export const HeaderCart = styled.View`
  background: ${style.colors.white};
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  margin-bottom: 20px;
`;
export const Title = styled.Text`
  color: ${props => props.theme.colors.primary};
  font-family: ${style.fontName.ubuntuBold};
  font-size: ${style.fonts.large};
`;
export const ButtonContainer = styled.View``;
export const TotalContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;
export const TotalTitle = styled.Text`
  color: ${props => props.theme.colors.primary};
  font-family: ${style.fontName.ubuntuBold};
  font-size: ${style.fonts.medium};
`;
export const TotalPrice = styled.Text`
  color: ${props => props.theme.colors.primary};
  font-family: ${style.fontName.ubuntuBold};
  font-size: ${style.fonts.medium};
`;

export const ConfirmContainer = styled.View`
  color: ${props => props.theme.colors.primary};
  font-family: ${style.fontName.ubuntuBold};
  font-size: ${style.fonts.medium};
`;

export const InputTextContainer = styled.View`
  color: ${props => props.theme.colors.primary};
  font-family: ${style.fontName.ubuntuRegular};
  font-size: ${style.fonts.medium};
  padding: 10px;
  margin-bottom: 10px;
`;
export const InputText = styled.TextInput`
  color: ${props => props.theme.colors.primary};
  font-family: ${style.fontName.ubuntuRegular};
  font-size: ${style.fonts.medium};
  padding: 10px 0;
  border-bottom-width: 1px;
  border-bottom-color: #000;
  margin-bottom: 5px;
  min-width: 270px;
`;

export const CloseButton = styled.TouchableOpacity`
  align-self: flex-end;
`;
