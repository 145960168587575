import React from 'react';
import {Container, Text} from './styles';

const CustomButton = ({onPress, children}) => {
  return (
    <Container onPress={onPress}>
      <Text>{children}</Text>
    </Container>
  );
};

export default CustomButton;
