import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {setDelivery, setPayment} from '../../../Store/CartStore';

import {Container, PriceRadio, Label, NameContainer, Image} from './styles';

const PaymentOption = ({data}) => {
  const {name, id, icon} = data;
  const {payment} = useSelector(state => state.cart);
  const dispatch = useDispatch();

  const handleSelect = () => {
    dispatch(setPayment(data));
  };
  return (
    <TouchableOpacity onPress={handleSelect}>
      <Container>
        <NameContainer>
          <Image source={{uri: icon}} resizeMode="contain" />
          <Label>{name}</Label>
        </NameContainer>
        <PriceRadio selected={payment?.id === id} />
      </Container>
    </TouchableOpacity>
  );
};

export default PaymentOption;
