import React from 'react';
import {useSelector} from 'react-redux';

// import { Container } from './styles';

function Logo() {
  const {store} = useSelector(state => state.general);
  const {primary, secondary} = store.theme;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="248.444"
      height="63.255"
      viewBox="0 0 248.444 63.255">
      <g
        id="Grupo_137"
        data-name="Grupo 137"
        transform="translate(-103.758 -2298)">
        <g
          id="Grupo_136"
          data-name="Grupo 136"
          transform="translate(103.758 2298)">
          <g
            id="Grupo_131"
            data-name="Grupo 131"
            transform="translate(76.754 17.025)">
            <path
              id="Caminho_31"
              data-name="Caminho 31"
              d="M1041.528,196.951q.747,1.293,1.666,3.163t1.87,3.926q.951,2.057,1.887,4.182t1.717,3.927q.781-1.8,1.7-3.927t1.87-4.182q.952-2.056,1.87-3.926t1.665-3.163h3.86q.311,2.584.589,5.491t.5,5.95q.225,3.043.415,6.12t.329,6h-4.216q-.139-4.113-.33-8.466t-.571-8.431q-.375.783-.884,1.87t-1.089,2.363q-.578,1.274-1.172,2.635t-1.156,2.635q-.561,1.274-1.037,2.38t-.817,1.887H1047q-.341-.782-.816-1.9t-1.036-2.4q-.561-1.274-1.156-2.635t-1.173-2.617q-.578-1.258-1.088-2.364t-.884-1.852q-.382,4.08-.572,8.431t-.329,8.466h-4.216q.138-2.925.328-6.052t.415-6.2q.225-3.076.5-5.967t.589-5.338Z"
              transform="translate(-1035.731 -194.129)"
              fill={secondary}
            />
            <path
              id="Caminho_32"
              data-name="Caminho 32"
              d="M1083.349,214.982a11.171,11.171,0,0,1,.7-4.114,8.62,8.62,0,0,1,1.854-2.941,7.664,7.664,0,0,1,2.651-1.768,8.223,8.223,0,0,1,3.06-.595,7.325,7.325,0,0,1,5.729,2.278q2.056,2.278,2.057,6.8,0,.34-.017.765t-.052.765H1087.6a4.687,4.687,0,0,0,1.514,3.315A5.744,5.744,0,0,0,1093,220.66a12.781,12.781,0,0,0,2.737-.273,11.241,11.241,0,0,0,1.955-.578l.544,3.366a6.509,6.509,0,0,1-.935.357,13.032,13.032,0,0,1-1.36.34q-.765.153-1.649.255a15.671,15.671,0,0,1-1.8.1,10.908,10.908,0,0,1-4.079-.7,7.591,7.591,0,0,1-2.856-1.938,7.931,7.931,0,0,1-1.666-2.924A11.923,11.923,0,0,1,1083.349,214.982Zm11.934-1.836a5.421,5.421,0,0,0-.238-1.615,3.892,3.892,0,0,0-.7-1.326,3.3,3.3,0,0,0-1.122-.884,3.583,3.583,0,0,0-1.581-.323,3.678,3.678,0,0,0-1.666.357,3.8,3.8,0,0,0-1.208.935,4.308,4.308,0,0,0-.766,1.326,7.207,7.207,0,0,0-.373,1.53Z"
              transform="translate(-1053.839 -197.404)"
              fill={secondary}
            />
            <path
              id="Caminho_33"
              data-name="Caminho 33"
              d="M1116.265,206.626a27.661,27.661,0,0,1,3.093-.646,26.707,26.707,0,0,1,4.217-.306,9.709,9.709,0,0,1,3.638.595,5.682,5.682,0,0,1,2.329,1.666,6.366,6.366,0,0,1,1.224,2.584,14.435,14.435,0,0,1,.357,3.316v10h-4.114v-9.35a13.547,13.547,0,0,0-.186-2.431,4.321,4.321,0,0,0-.612-1.632,2.446,2.446,0,0,0-1.157-.918,4.869,4.869,0,0,0-1.785-.289,13.645,13.645,0,0,0-1.632.1q-.851.1-1.258.17v14.348h-4.114Z"
              transform="translate(-1066.356 -197.446)"
              fill={secondary}
            />
            <path
              id="Caminho_34"
              data-name="Caminho 34"
              d="M1162.818,223.515q-1.191.306-3.094.628a25.032,25.032,0,0,1-4.181.323,9.213,9.213,0,0,1-3.587-.612,5.747,5.747,0,0,1-2.312-1.7,6.677,6.677,0,0,1-1.241-2.6,13.748,13.748,0,0,1-.374-3.315v-9.961h4.114v9.316a7.51,7.51,0,0,0,.833,4.08,3.26,3.26,0,0,0,2.907,1.225q.748,0,1.581-.068a8.25,8.25,0,0,0,1.241-.17V206.277h4.113Z"
              transform="translate(-1078.435 -197.675)"
              fill={secondary}
            />
            <path
              id="Caminho_35"
              data-name="Caminho 35"
              d="M1195.561,216.806v3.706H1180.67V196.951h4.283v19.855Z"
              transform="translate(-1090.848 -194.129)"
              fill={secondary}
            />
            <path
              id="Caminho_36"
              data-name="Caminho 36"
              d="M1207.5,214.982a11.171,11.171,0,0,1,.7-4.114,8.618,8.618,0,0,1,1.854-2.941,7.661,7.661,0,0,1,2.652-1.768,8.216,8.216,0,0,1,3.06-.595,7.324,7.324,0,0,1,5.728,2.278q2.057,2.278,2.058,6.8c0,.227-.006.482-.018.765s-.028.539-.051.765h-11.729a4.69,4.69,0,0,0,1.512,3.315,5.75,5.75,0,0,0,3.894,1.173,12.781,12.781,0,0,0,2.737-.273,11.233,11.233,0,0,0,1.955-.578l.544,3.366a6.521,6.521,0,0,1-.935.357,12.961,12.961,0,0,1-1.36.34q-.765.153-1.649.255a15.664,15.664,0,0,1-1.8.1,10.914,10.914,0,0,1-4.08-.7,7.588,7.588,0,0,1-2.855-1.938,7.94,7.94,0,0,1-1.667-2.924A11.945,11.945,0,0,1,1207.5,214.982Zm11.934-1.836a5.4,5.4,0,0,0-.239-1.615,3.871,3.871,0,0,0-.7-1.326,3.306,3.306,0,0,0-1.122-.884,3.583,3.583,0,0,0-1.581-.323,3.678,3.678,0,0,0-1.666.357,3.8,3.8,0,0,0-1.208.935,4.282,4.282,0,0,0-.765,1.326,7.187,7.187,0,0,0-.374,1.53Z"
              transform="translate(-1101.049 -197.404)"
              fill={secondary}
            />
            <path
              id="Caminho_37"
              data-name="Caminho 37"
              d="M1254.5,222.027q0,4.25-2.159,6.221t-6.613,1.972a18.368,18.368,0,0,1-3.178-.271,17.768,17.768,0,0,1-2.8-.715l.748-3.5a12.888,12.888,0,0,0,2.4.714,14.671,14.671,0,0,0,2.907.272,4.994,4.994,0,0,0,3.536-1.02,4.028,4.028,0,0,0,1.054-3.026v-.68a10.573,10.573,0,0,1-1.616.612,7.871,7.871,0,0,1-2.295.306,8.566,8.566,0,0,1-3.111-.544,6.509,6.509,0,0,1-2.414-1.6,7.289,7.289,0,0,1-1.564-2.635,10.9,10.9,0,0,1-.562-3.654,10.348,10.348,0,0,1,.6-3.57,7.631,7.631,0,0,1,1.734-2.788,7.923,7.923,0,0,1,2.771-1.8,10,10,0,0,1,3.705-.646,22.752,22.752,0,0,1,3.808.306q1.8.306,3.06.646Zm-11.458-7.548a5.431,5.431,0,0,0,1.139,3.824,3.863,3.863,0,0,0,2.941,1.208,6.149,6.149,0,0,0,1.853-.273,5.279,5.279,0,0,0,1.411-.646V209.38q-.443-.1-1.088-.187a13.071,13.071,0,0,0-1.632-.085,4.15,4.15,0,0,0-3.434,1.479A6.018,6.018,0,0,0,1243.037,214.48Z"
              transform="translate(-1112.961 -197.446)"
              fill={secondary}
            />
            <path
              id="Caminho_38"
              data-name="Caminho 38"
              d="M1277.7,205.564a10.072,10.072,0,0,1,3.434.51,5.48,5.48,0,0,1,2.227,1.428,5.344,5.344,0,0,1,1.19,2.227,10.915,10.915,0,0,1,.358,2.873v10.777q-.953.2-2.873.493a29.336,29.336,0,0,1-4.335.289,13.016,13.016,0,0,1-2.924-.306,6.13,6.13,0,0,1-2.261-.986,4.645,4.645,0,0,1-1.462-1.767,6.132,6.132,0,0,1-.527-2.686,5.18,5.18,0,0,1,.6-2.584,4.879,4.879,0,0,1,1.616-1.718,7,7,0,0,1,2.362-.952,13.347,13.347,0,0,1,2.805-.289,12.7,12.7,0,0,1,1.427.085,13.308,13.308,0,0,1,1.6.289v-.68a5.313,5.313,0,0,0-.17-1.36,2.72,2.72,0,0,0-1.717-1.9,4.867,4.867,0,0,0-1.751-.271,15.482,15.482,0,0,0-2.617.2,11.532,11.532,0,0,0-1.938.476l-.51-3.332a16.218,16.218,0,0,1,2.277-.544A17.892,17.892,0,0,1,1277.7,205.564Zm.34,15.333a15.289,15.289,0,0,0,2.89-.2v-4.556a7.934,7.934,0,0,0-.986-.2,9.173,9.173,0,0,0-1.428-.1,9.467,9.467,0,0,0-1.377.1,4.278,4.278,0,0,0-1.258.374,2.283,2.283,0,0,0-.9.765,2.133,2.133,0,0,0-.34,1.241,2.175,2.175,0,0,0,.918,2.023A4.732,4.732,0,0,0,1278.044,220.9Z"
              transform="translate(-1125.019 -197.404)"
              fill={secondary}
            />
            <path
              id="Caminho_39"
              data-name="Caminho 39"
              d="M1308,219.12a11.17,11.17,0,0,1-3.043-.408,4.673,4.673,0,0,1-1.921-1.071,3.793,3.793,0,0,1-1-1.734,8.808,8.808,0,0,1-.29-2.363V193.077l4.115-.68v20.366a5.693,5.693,0,0,0,.117,1.257,2.014,2.014,0,0,0,.426.867,1.9,1.9,0,0,0,.833.544,6.49,6.49,0,0,0,1.343.289Z"
              transform="translate(-1136.889 -192.397)"
              fill={secondary}
            />
          </g>
          <g id="Grupo_135" data-name="Grupo 135" transform="translate(0 0)">
            <path
              id="Caminho_45"
              data-name="Caminho 45"
              d="M31.627,0A31.627,31.627,0,1,1,0,31.627,31.627,31.627,0,0,1,31.627,0Z"
              fill={secondary}
            />
            <g
              id="Grupo_132"
              data-name="Grupo 132"
              transform="translate(23.501 7.731)">
              <path
                id="Caminho_40"
                data-name="Caminho 40"
                d="M972.144,218.807H949.8V177.4h22.343Zm-19.864-2.479h17.385V179.88H952.28Z"
                transform="translate(-949.801 -177.401)"
                fill="#1a1818"
              />
            </g>
            <g
              id="Grupo_133"
              data-name="Grupo 133"
              transform="translate(9.056 16.001)">
              <path
                id="Caminho_41"
                data-name="Caminho 41"
                d="M949.623,226.186h-23.13v-35.44h23.13Zm-20.651-2.479h18.172V193.225H928.972Z"
                transform="translate(-926.493 -190.746)"
                fill="#1a1818"
              />
            </g>
            <g
              id="Grupo_134"
              data-name="Grupo 134"
              transform="translate(29.707 24.542)">
              <path
                id="Caminho_42"
                data-name="Caminho 42"
                d="M982.9,234.035H959.816V204.527H982.9Zm-20.6-2.479h18.123v-24.55H962.3Z"
                transform="translate(-959.816 -204.527)"
                fill="#1a1818"
              />
            </g>
            <path
              id="Caminho_47"
              data-name="Caminho 47"
              d="M0,0H3.728V28.371H0Z"
              transform="translate(25.966 18.328)"
              fill={primary}
            />
            <path
              id="Caminho_48"
              data-name="Caminho 48"
              d="M0,0H11.257V19.652H0Z"
              transform="translate(32.179 27.047)"
              fill={primary}
            />
            <path
              id="Caminho_49"
              data-name="Caminho 49"
              d="M0,0H12.055V30.651H0Z"
              transform="translate(11.445 18.487)"
              fill="#fff"
            />
            <g
              id="Grupo_146"
              data-name="Grupo 146"
              transform="translate(32.179 10.179)">
              <path
                id="Caminho_50"
                data-name="Caminho 50"
                d="M0,0H11.257V14.363H0Z"
                fill="#fff"
              />
            </g>
            <g
              id="Grupo_145"
              data-name="Grupo 145"
              transform="translate(25.966 10.179)">
              <path
                id="Caminho_51"
                data-name="Caminho 51"
                d="M0,0H11.357V5.846H0Z"
                fill="#fff"
              />
            </g>
            <g
              id="Grupo_147"
              data-name="Grupo 147"
              transform="translate(45.844 27.047)">
              <path
                id="Caminho_50-2"
                data-name="Caminho 50"
                d="M0,0H4.447V24.4H0Z"
                transform="translate(0 0)"
                fill="#fff"
              />
            </g>
            <g
              id="Grupo_148"
              data-name="Grupo 148"
              transform="translate(32.179 49.138)">
              <path
                id="Caminho_50-3"
                data-name="Caminho 50"
                d="M0,0H13.664V2.3H0Z"
                transform="translate(0 0)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Logo;
