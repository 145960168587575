import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  Container,
  Title,
  HeaderCart,
  ItemsContainer,
  ButtonContainer,
  TotalPrice,
  TotalContainer,
  TotalTitle,
  InputText,
  InputTextContainer,
  ConfirmContainer,
  CloseButton,
  Label,
} from './styles';

import {View} from 'react-native';
import CartItem from './CartItem';
import BackArrow from '../../Components/BackArrow';

import CustomButton from '../../Components/CustomButton';
import {formatCurrency} from '../../Helpers/currency';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {EVENT_TYPE} from '../../Services/API/schemas';
import Checkout from './Checkout';
import {CreateEvent} from '../../Services/API/store';
import {FlatList} from 'react-native';
import DeliveryOption from './DeliveryOption';
import {setDelivery, setPayment} from '../../Store/CartStore';
import PaymentOption from './PaymentOption';

export const getDeliveryOptions = store => {
  let options = [];

  switch (store.whatsappDeliveryTax) {
    case -2:
      options.push({
        id: -2,
        name: 'Retirada em loja',
        value: 0,
        display: 'Grátis',
      });
      break;
    case -1:
      options.push({
        id: -1,
        name: 'A combinar',
        value: 0,
        display: 'A combinar',
      });
      break;
    case 0:
      options.push({
        id: 0,
        name: 'Entrega padrão',
        display: 'Grátis',
        value: 0,
      });
      break;
    default:
      options.push({
        id: 'monetario',
        name: `Entrega padrão`,
        value: store.whatsappDeliveryTax,
        display: formatCurrency(store.whatsappDeliveryTax, store?.currency),
      });
      break;
  }

  if (store.hasTakeAway && store.whatsappDeliveryTax !== -2) {
    options.push({
      id: -2,
      name: 'Retirada em Loja',
      display: 'Grátis',
      value: 0,
    });
  }

  return options;
};

const Cart = ({navigation}) => {
  const scrollView = useRef(null);
  const cart = useSelector(state => state.cart);
  const {payment} = cart;
  const [clientModal, setClientModal] = useState(false);
  const {store} = useSelector(state => state.general);
  const dispatch = useDispatch();
  const [finishButtonText, setFinishButtonText] = useState(
    'Fazer pedido pelo Whatsapp',
  );

  useEffect(() => {
    if (store.hasPayment && store.payments.length > 0 && !payment) {
      setFinishButtonText('Selecione a forma de pagamento');
    } else {
      setFinishButtonText('Fazer pedido pelo Whatsapp');
    }
  }, [payment]);

  useEffect(() => {
    window.history.pushState('cart', 'cart', '#cart');
    CreateEvent(EVENT_TYPE.VIEW_CART);
    dispatch(setPayment(null));
  }, []);

  const handleCheckout = () => {
    //setClientModal(true);
    scrollView.current.scrollTo({y: 1000});
    if (store.hasPayment && store.payments.length > 0 && !payment) {
      toast.error('Selecione a forma de pagamento', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    setClientModal(true);
  };

  navigation.setOptions({title: store.name});

  return (
    <>
      <Container>
        <ToastContainer />
        <HeaderCart>
          <BackArrow colored />
          <Title>Carrinho</Title>
        </HeaderCart>
        <Checkout clientModal={clientModal} setClientModal={setClientModal} />
        <ItemsContainer ref={scrollView}>
          <Label>Produtos</Label>
          <FlatList
            data={cart?.items}
            renderItem={({item}) => <CartItem item={item} />}
          />

          {store.hasTakeAway && (
            <>
              <Label>Entrega</Label>
              <FlatList
                data={getDeliveryOptions(store)}
                renderItem={({item}) => <DeliveryOption data={item} />}
              />
            </>
          )}
          {store.hasPayment && store.payments.length > 0 && (
            <>
              <Label>Forma de Pagamento</Label>
              <FlatList
                data={store.payments}
                renderItem={({item}) => <PaymentOption data={item} />}
              />
            </>
          )}
        </ItemsContainer>
        <ButtonContainer>
          <TotalContainer>
            <TotalTitle>Itens</TotalTitle>
            <TotalPrice>
              {formatCurrency(cart?.totalItems, store?.currency)}
            </TotalPrice>
          </TotalContainer>
          <TotalContainer>
            <TotalTitle>Taxa de entrega</TotalTitle>
            <TotalPrice>{cart.delivery.display}</TotalPrice>
          </TotalContainer>

          <TotalContainer>
            <TotalTitle>Total</TotalTitle>
            <TotalPrice>
              {formatCurrency(cart?.total, store?.currency)}
            </TotalPrice>
          </TotalContainer>
          <CustomButton
            accessibilityRole="link"
            target="_blank"
            onPress={() => handleCheckout()}>
            {finishButtonText}
          </CustomButton>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default Cart;
