import * as React from 'react';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {Dimensions, SafeAreaView} from 'react-native';
import Routes from './Routes';
import {store, persistor} from './Store';
import {cleanText} from './Helpers/strings';

function App() {
  cleanText();
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SafeAreaView
          style={{
            flex: 1,
            justifyContent: 'center',
            height: Dimensions.get('window').height,
            backgroundColor: '#fff',
            maxWidth: 768,
            margin: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}>
          <Routes />
        </SafeAreaView>
      </PersistGate>
    </Provider>
  );
}

export default App;
