import styled from 'styled-components/native';
import style from './../../styles/index';

export const DataContainer = styled.ScrollView`
  background: ${style.colors.white};
  flex: 1;
`;
export const Container = styled.View`
  background: ${style.colors.white};
  flex: 1;
`;

