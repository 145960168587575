import styled from 'styled-components/native';
import style from '../../../styles/index';
import {Picker as PickerComponent} from 'react-native-web';

export const Container = styled.View`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  border-bottom: 3px solid #ccc;
`;
export const PickerNameContainer = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
`;
export const Picker = styled(PickerComponent)`
  background: ${style.colors.white};
  text-align: center;
  width: 45px;
  border: 1px solid ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primary};
  align-items: center;
  justify-content: center;
  padding-left: 3px;
  font-family: ${style.fontName.ubuntuRegular};
  font-size: ${style.fonts.xxsmall};
`;

export const NameContainer = styled.View`
  margin-left: 20px;
  margin-right: 10px;
`;
export const Name = styled.Text`
  color: ${style.colors.black};
  font-weight: bold;
  max-width: 200px;
  font-family: ${style.fontName.ubuntuBold};
  font-size: ${style.fonts.xsmall};
`;
export const PriceTitle = styled.Text`
  color: ${style.colors.black};
  font-family: ${style.fontName.ubuntuRegular};
  font-size: ${style.fonts.xsmall};
  margin-top: 5px;
`;
export const Price = styled.Text`
  color: ${style.colors.black};
  font-family: ${style.fontName.ubuntuBold};
  font-size: ${style.fonts.xsmall};
  justify-content: center;
`;
export const Separator = styled.View`
  height: 1px;
  border-width: 1px;
  border-color: #dedede;
  margin-top: 5px;
  margin-bottom: 10px;
`;
