import React, {useCallback, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
// import { Container } from './styles';
import Home from '../Screens/Home';
import Description from '../Screens/Description';
import NotFound from '../Screens/NotFound';
import {ActivityIndicator} from 'react-native';
import Cart, {getDeliveryOptions} from '../Screens/Cart';
import {GetStoreInfo, CreateEvent} from '../Services/API/store';
import {EVENT_TYPE} from '../Services/API/schemas';
import {reset, setDelivery} from '../Store/CartStore';
import {setStore} from '../Store/GeneralStore';
import {setLoading} from '../Store/LoadingStore';
import ThemeProvider from '../styles/themeProvider';

const Stack = createStackNavigator();

const Routes = () => {
  const dispatch = useDispatch();
  const [storeFound, setStoreFound] = useState(true);

  const loading = useSelector(state => state.loading);
  const cart = useSelector(state => state.cart);
  const store = useSelector(state => state.store);

  const loadData = useCallback(async () => {
    dispatch(setLoading(true));
    const store = await GetStoreInfo();
    if (store === 404) {
      setStoreFound(false);
      dispatch(setLoading(false));
      return;
    }

    if (window.location?.search?.search('qrcode_origin')) {
      const originValue = window.location?.search.split('?qrcode_origin=');
      if (originValue[1] == 1) {
        CreateEvent(EVENT_TYPE.VIEW_MENU_QRCODE);
      }
    }
    //store.hasSearch = true;

    CreateEvent(EVENT_TYPE.VIEW_MENU);

    dispatch(setStore(store));
    let options = getDeliveryOptions(store);
    dispatch(setDelivery(options[0]));
    //dispatch(setDelivery(store.whatsappDeliveryTax));
    dispatch(setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (loading.show) {
    return <ActivityIndicator />;
  }

  if (!storeFound) {
    return <NotFound />;
  }

  return (
    <ThemeProvider>
      <NavigationContainer>
        <Stack.Navigator headerMode={'none'}>
          <Stack.Screen name="Home" component={Home} />
          <Stack.Screen name="Cart" component={Cart} />
          <Stack.Screen name="Description" component={Description} />
          <Stack.Screen name="NotFound" component={NotFound} />
        </Stack.Navigator>
      </NavigationContainer>
    </ThemeProvider>
  );
};

export default Routes;
