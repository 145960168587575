import styled from 'styled-components/native';
import style, {hpx} from './../../styles/index';

export const PriceSelectorContainer = styled.View`
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
`;
export const PriceContainer = styled.TouchableOpacity`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: ${hpx(20)} ${hpx(20)} 0px 0px;
`;

export const PriceTitle = styled.Text`
  font-size: ${style.fonts.medium};
  font-family: ${style.fontName.ubuntuRegular};
  margin-bottom: ${hpx(10)};
  color: black;
`;

export const PriceRadio = styled.View`
  background-color: ${props =>
    props.selected ? props.theme.colors.primary : 'transparent'};
  border: 1px solid ${props => props.theme.colors.primary};
  width: ${hpx(20)};
  height: ${hpx(20)};
  border-radius: 100%;
`;

export const Label = styled.Text`
  font-family: ${style.fontName.ubuntuRegular};
  font-size: ${style.fonts.medium};
  color: ${style.colors.gray};
`;
