import React from 'react';
import {Container, Category, Title, Description, DataContainer} from './styles';

const ItemDescription = ({item}) => {
  return (
    <Container>
      <DataContainer>
        <Category>{item.category.name}</Category>
        <Title>{item.name} </Title>
        <Description>{item.description}</Description>
      </DataContainer>
    </Container>
  );
};

export default ItemDescription;
