import React from 'react';
import {
  Container,
  Text,
  Count,
  Total,
  ButtonContainer,
  TextContainer,
} from './styles';
import {formatCurrency} from '../../Helpers/currency';

const CartButton = ({cart, onPress, children, currency}) => {
  return (
    <ButtonContainer>
      <Container onPress={onPress}>
        <TextContainer>
          <Count>{cart?.items?.length}</Count>
        </TextContainer>

        <Text>{children}</Text>

        <Total>{formatCurrency(cart?.total, currency)}</Total>
      </Container>
    </ButtonContainer>
  );
};

export default CartButton;
