import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import placeholder from '../../Assets/Images/placeholder.png';
import 'react-awesome-slider/dist/styles.css';
import './styles.css';
// import { Container } from './styles';

function ImageSlider({photos}) {
  const images = photos.length > 0 ? photos : [placeholder];
  return (
    <AwesomeSlider>
      {images.map((i, index) => (
        <div key={i + index}>
          <img
            alt="plate"
            key={i + index}
            src={i}
            style={{
              objectFit: 'contain',
              height: '450px',
              width: '100%',
            }}
          />
        </div>
      ))}
    </AwesomeSlider>
  );
}

export default ImageSlider;
