import styled from 'styled-components/native';
import style from '../../styles';

export const Container = styled.ImageBackground`
  background-size: contain;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const LogoContainer = styled.View`
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.Image`
  width: 130px;
  height: 35px;
`;

export const LogoText = styled.Text`
  font-family: ${style.fontName.ubuntuRegular};
  font-size: ${style.fonts.medium};
  color: white;
  opacity: 0.7;
  margin-top: 5px;
`;

export const TextContainer = styled.View`
  margin: 0 auto;
  justify-content: center;
  height: 250px;
`;

export const Title = styled.Text`
  font-family: ${style.fontName.ubuntuRegular};
  font-size: ${style.fonts.xxxlarge};
  color: white;
`;

export const Text = styled.Text`
  font-family: ${style.fontName.ubuntuRegular};
  font-size: ${style.fonts.large};
  color: white;
`;
