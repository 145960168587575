import styled from 'styled-components/native';

export const Container = styled.TouchableOpacity`
  background: ${props => {
    return props.theme.colors.primary;
  }};
  height: 50px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;
export const ButtonContainer = styled.View`
  padding: 2px;
`;
export const TextContainer = styled.View`
  border: 1px solid ${props => props.theme.colors.secondary};
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
`;
export const Text = styled.Text`
  font-size: 18px;
  color: ${props => props.theme.colors.secondary};
`;
export const Count = styled.Text`
  font-size: 18px;
  color: ${props => props.theme.colors.secondary};
`;
export const Total = styled.Text`
  font-size: 18px;
  color: ${props => props.theme.colors.secondary};
`;
