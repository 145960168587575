const types = {
  SET_STORE: 'SET_STORE/GENERAL',
};

const INITIAL_STATE = {
  store: {
    theme: {
      colors: {
        primary: '#fff',
        secondary: '#fff',
      },
    },
    // hasTakeAway: true,
  },
};

export const setStore = store => {
  return {
    type: types.SET_STORE,
    payload: {
      store,
    },
  };
};

const _setStore = (state, {store}) => {
  return {...state, store};
};

export default function reducer(state = INITIAL_STATE, action) {
  const {type, payload} = action;
  switch (type) {
    case types.SET_STORE:
      return _setStore(state, payload);

    default:
      return state;
  }
}
