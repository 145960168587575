const types = {
  SET_LOADING: 'SET_LOADING/LOADING',
};

const INITIAL_STATE = {
  show: true,
};

export const setLoading = show => {
  return {
    type: types.SET_LOADING,
    payload: {
      show,
    },
  };
};

const _setLoading = (state, {show}) => {
  return {...state, show};
};

export default function reducer(state = INITIAL_STATE, action) {
  const {type, payload} = action;
  switch (type) {
    case types.SET_LOADING:
      return _setLoading(state, payload);

    default:
      return state;
  }
}
