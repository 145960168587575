import React from 'react';
import {useSelector} from 'react-redux';

import {Linking, Platform, TouchableOpacity} from 'react-native';
import {ReactComponent as Whatsapp} from '../../Assets/Images/whatsappIcon.svg';
import {ReactComponent as Instagram} from '../../Assets/Images/instagram.svg';
import {ReactComponent as Facebook} from '../../Assets/Images/facebook.svg';
import {ReactComponent as Phone} from '../../Assets/Images/phoneIcon.svg';
import {ReactComponent as MoreInfo} from '../../Assets/Images/moreInformation.svg';
import {ReactComponent as LessInfo} from '../../Assets/Images/lessInfo.svg';
import {ReactComponent as Location} from '../../Assets/Images/location.svg';
import {
  Container,
  PhoneNumber,
  Address,
  Name,
  PhoneNumberButton,
  AddressContainer,
  AddressIcon,
  TextContainer,
  PhoneContainer,
  MapLink,
  CoverContainer,
  Search,
  SearchContainer,
  MoreInformationContainer,
  MoreInformationHidden,
  Description,
} from './styles';
import CoverImage from './CoverImage/index.web';

import {ReactComponent as PhoneIcon} from '../../Assets/Images/phone.svg';
import {ReactComponent as WhatsappIcon} from '../../Assets/Images/whatsapp.svg';
import {ReactComponent as SearchIcon} from '../../Assets/Images/search.svg';

import {CreateEvent} from '../../Services/API/store';
import {EVENT_TYPE} from '../../Services/API/schemas';
import {memo} from 'react';
import SectionSeparator from '../SectionSeparator';
import HeaderItem from './HeaderItem';
import {useState} from 'react';
import {sanitizeSocialMediaName} from '../../Helpers/general';

const HeaderHome = props => {
  const {store} = useSelector(state => state.general);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const openUrl = url => {
    if (Platform.OS === 'web') {
      window.open(url);
    } else {
      Linking.openURL(url);
    }
  };
  const handleMoreInfo = () => {
    setShowMoreInfo(!showMoreInfo);
    console.log();
  };

  const hasMoreinfo = () => {
    if (
      store?.phone?.length > 3 ||
      store?.whatsapp?.length > 3 ||
      store?.facebook?.length > 3 ||
      store?.instagram?.length > 3
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <Container height={props.height}>
        <CoverContainer>
          <CoverImage url={store.theme.cover.url} />
          {/* <Cover source={{uri: store.theme.cover.url}} /> */}
        </CoverContainer>
        <TextContainer>
          <Name>{store.name}</Name>
          {store?.description && (
            <Description>{store?.description}</Description>
          )}

          {store?.addresses[0] && (
            <AddressContainer
              onPress={() => {
                CreateEvent(EVENT_TYPE.CLICK_ADDRESS);

                openUrl(
                  `https://www.google.com/maps/search/?api=1&query=${
                    store?.addresses[0]?.street
                  } ${store.addresses[0]?.city} ${store.addresses[0]?.state}`,
                );
              }}>
              <Location
                fill={store?.theme.primary}
                style={{marginRight: 10, marginLeft: 8, width: 15, height: 15}}
              />
              <Address>
                {store?.addresses[0]?.street}
                {store.addresses[0]?.city && ' - ' + store.addresses[0]?.city}
                {store.addresses[0]?.state &&
                  ' - ' + store.addresses[0]?.state}{' '}
                - <MapLink>ver no mapa</MapLink>
              </Address>
            </AddressContainer>
          )}
          <MoreInformationContainer>
            {showMoreInfo && (
              <MoreInformationHidden>
                {store?.phone && store?.phone.length > 3 && (
                  <HeaderItem
                    Icon={<Phone fill={store?.theme.primary} />}
                    onPress={() => {
                      CreateEvent(EVENT_TYPE.CLICK_CALL);
                      openUrl(`tel://${store?.phone.replace('+', '')}`);
                    }}>
                    {store?.phone}
                  </HeaderItem>
                )}

                {store?.whatsapp && store?.whatsapp.length > 3 && (
                  <HeaderItem
                    Icon={<Whatsapp fill={store?.theme.primary} />}
                    onPress={() => {
                      CreateEvent(EVENT_TYPE.CLICK_WHATSAPP);

                      Linking.openURL(
                        `https://wa.me/${store?.whatsapp.replace(
                          '+',
                          '',
                        )}?text=Encontrei%20este%20estabelecimento%20no%20Menu%20Legal%20`,
                      );
                    }}>
                    {store?.whatsapp}
                  </HeaderItem>
                )}

                {store?.facebook && store?.facebook.length > 3 && (
                  <HeaderItem
                    Icon={<Facebook fill={store?.theme.primary} />}
                    onPress={() => {
                      CreateEvent(EVENT_TYPE.CLICK_FACEBOOK);
                      Linking.openURL(
                        `https://www.facebook.com/${sanitizeSocialMediaName(
                          store?.facebook,
                        )}`,
                      );
                    }}>
                    Acessar facebook
                  </HeaderItem>
                )}

                {store?.instagram && store?.instagram.length > 3 && (
                  <HeaderItem
                    Icon={<Instagram fill={store?.theme.primary} />}
                    onPress={() => {
                      CreateEvent(EVENT_TYPE.CLICK_INSTAGRAM);
                      Linking.openURL(
                        `https://www.instagram.com/${sanitizeSocialMediaName(
                          store?.instagram,
                        )}`,
                      );
                    }}>
                    @{sanitizeSocialMediaName(store?.instagram)}
                  </HeaderItem>
                )}
              </MoreInformationHidden>
            )}

            {hasMoreinfo() && (
              <HeaderItem
                onPress={handleMoreInfo}
                noborder
                Icon={
                  showMoreInfo ? (
                    <LessInfo fill={store?.theme.primary} />
                  ) : (
                    <MoreInfo fill={store?.theme.primary} />
                  )
                }>
                {showMoreInfo ? 'Menos Informações' : 'Mais Informações'}
              </HeaderItem>
            )}
          </MoreInformationContainer>
        </TextContainer>
      </Container>
      <SectionSeparator />
      {store.hasSearch && (
        <SearchContainer>
          <SearchIcon
            fill={'#ccc'}
            style={{height: 25, width: 25, marginRight: 10}}
          />

          <Search
            type="text"
            placeholder="Buscar produto"
            onChangeText={value => props.handleSearch(value)}
          />
        </SearchContainer>
      )}
    </>
  );
};

export default memo(HeaderHome);
