export const EVENT_TYPE = {
  VIEW_MENU: 'view_menu',
  VIEW_MENU_QRCODE: 'view_menu_qrcode',
  VIEW_PRODUCT: 'view_product',
  VIEW_CART: 'view_cart',
  CLICK_ADDRESS: 'click_address',
  CLICK_CALL: 'click_call',
  CLICK_WHATSAPP: 'click_whatsapp',
  CLICK_FACEBOOK: 'click_facebook',
  CLICK_INSTAGRAM: 'click_instagram',
  CLICK_CATEGORY: 'click_category',
  ADD_TO_CART: 'add_to_cart',
  BUY_PRODUCT: 'buy_product',
  ORDER: 'order',
};
