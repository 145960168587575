import styled from 'styled-components/native';
import style, {hpx} from '../../styles';
export const CategoriesContainer = styled.ImageBackground`
  padding-vertical: ${hpx(10)};
  height: ${hpx(60)};

  
`;

export const Container = styled.TouchableOpacity`
  border-bottom-color: ${props => props.theme.colors.primary};
  border-bottom-width: ${props => (props.active ? '3px' : '0')};
  margin-left: ${hpx(20)};
  
  
`;

export const Name = styled.Text`
  color: ${props => props.theme.colors.primary};
  font-family: ${style.fontName.ubuntuBold};
  font-size: ${style.fonts.small};
  
`;
