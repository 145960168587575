import React from 'react';
import {useSelector} from 'react-redux';

import {Container, Text, Label} from './styles';

const ItemNotes = ({onChange}) => {
  const {store} = useSelector(state => state.general);

  if (!store.hasWhatsappDelivery) return <></>;
  return (
    <Container>
      <Label>Observações</Label>
      <Text multiline onChange={e => onChange(e.target.value)} />
    </Container>
  );
};

export default ItemNotes;
