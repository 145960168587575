import React, {useRef, useEffect} from 'react';
import {flatten, uniqBy, sortBy} from 'lodash';
import {FlatList} from 'react-native';
import {useSelector} from 'react-redux';

import Category from './Category';

import {CategoriesContainer} from './styles';

const Categories = ({activeCategory, onPressItem, items}) => {
  const {store} = useSelector(state => state.general);
  const flatListRef = useRef();
  const data = sortBy(
    uniqBy(flatten(items?.map(i => i.category)), 'id'),
    item => {
      return item?.order;
    },
  );

  const _onPress = item => {
    onPressItem(item);
  };

  useEffect(() => {
    const index = data.findIndex(i => i?.id === activeCategory);
    if (index >= 0) {
      flatListRef.current.scrollToIndex({
        index: index,
      });
    }
  }, [data, activeCategory]);

  return (
    <CategoriesContainer
      resizeMode="repeat"
      source={require('../../Assets/Images/bgCategories.png')}>
      <FlatList
        ref={flatListRef}
        data={data}
        horizontal
        showsHorizontalScrollIndicator={false}
        keyExtractor={item => item?.id.toString()}
        renderItem={({item}) => (
          <Category
            onPress={() => _onPress(item)}
            active={activeCategory === item?.id}>
            {item?.name}
          </Category>
        )}
      />
    </CategoriesContainer>
  );
};

export default Categories;
