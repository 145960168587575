import React from 'react';

import {Container, Icon, Line, Point} from './styles';

const SectionSeparator = () => {
  return (
    <Container>
      <Line />
      {/* <Icon
        style={{width: 40, height: 40}}
        source={require('../../Assets/Images/forkIcon.png')}
      /> */}
      <Point />
      <Line />
    </Container>
  );
};

export default SectionSeparator;
