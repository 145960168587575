import {Platform, Linking} from 'react-native-web';

export const openUrl = url => {
  if (Platform.OS === 'web') {
    window.open(url, '_self');
  } else {
    Linking.openURL(url);
  }
};

export const sanitizeSocialMediaName = value => {
  return value
    .replace('https://m.facebook.com/', '')
    .replace('https://facebook.com/', '')
    .replace('https://www.facebook.com/', '')
    .replace('@', '')
    .replace('https://instagram.com/', '')
    .replace('https://www.instagram.com/', '');
};
