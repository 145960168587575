import styled from 'styled-components/native';
import {hpx} from '../../../styles';

export const Container = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
export const PriceRadio = styled.View`
  background-color: ${props =>
    props.selected ? props.theme.colors.primary : 'transparent'};
  border: 1px solid ${props => props.theme.colors.primary};
  width: ${hpx(20)};
  height: ${hpx(20)};
  border-radius: 100%;
`;
export const Label = styled.Text`
  font-size: 16px;
`;
