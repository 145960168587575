import styled from 'styled-components/native';
import style from '../../styles/index';

export const Container = styled.TouchableOpacity`
  background: ${props => props.theme.colors.primary};
  height: 50px;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
`;
export const Text = styled.Text`
  font-family: ${style.fontName.ubuntuBold};
  font-size: ${style.fonts.medium};
  color: ${props => props.theme.colors.secondary};
`;
