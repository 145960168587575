const types = {
  SET_DELIVERY: 'SET_DELIVERY/CART',
  SET_PAYMENT: 'SET_PAYMENT/CART',
  SET_PRODUCT: 'ADD_PRODUCT/CART',
  SET_CLIENT_NAME: 'SET_CLIENT_NAME/CART',
  SET_ADDRESS: 'SET_ADDRESS/CART',

  REMOVE_PRODUCT: 'REMOVE_PRODUCT/CART',
  RESET: 'RESET/CART',
};

const INITIAL_STATE = {
  items: [],
  totalItems: null,
  total: null,
  delivery: null,
  clientName: '',
  address: null,
  availableDelivery: [],
  payment: null,
};

const updateTotal = (items, delivery) => {
  const totalItems = items
    .map(i => i.price.value * i.quantity)
    .reduce((a, b) => a + b, 0);

  return {
    totalItems,
    total: delivery.value > 0 ? totalItems + delivery.value : totalItems,
  };
};

export const setClientName = clientName => {
  return {
    type: types.SET_CLIENT_NAME,
    payload: {
      clientName,
    },
  };
};

const _setClientName = (state, {clientName}) => {
  return {...state, clientName};
};

export const setAddress = address => {
  return {
    type: types.SET_ADDRESS,
    payload: {
      address,
    },
  };
};

const _setAddress = (state, {address}) => {
  return {...state, address};
};

export const setDelivery = delivery => {
  return {
    type: types.SET_DELIVERY,
    payload: {
      delivery,
    },
  };
};

const _setDelivery = (state, {delivery}) => {
  return {...state, delivery, ...updateTotal(state.items, delivery)};
};

export const setPayment = payment => {
  return {
    type: types.SET_PAYMENT,
    payload: {
      payment,
    },
  };
};

const _setPayment = (state, {payment}) => {
  return {...state, payment};
};

export const setProduct = product => {
  return {
    type: types.SET_PRODUCT,
    payload: {
      product,
    },
  };
};

const _setProduct = (state, {product}) => {
  let items = [...state.items];
  if (product.cartProductId) {
    let indexOf = items.findIndex(
      i => i.cartProductId === product.cartProductId,
    );
    items[indexOf] = product;
  } else items.push({...product, cartProductId: new Date().getTime()});

  return {...state, items, ...updateTotal(items, state.delivery)};
};

const _reset = state => {
  return {
    ...INITIAL_STATE,
    delivery: state.delivery,
    clientName: state.clientName,
    address: state.address,
  };
};

export const reset = () => {
  return {
    type: types.RESET,
  };
};

export const removeProduct = product => {
  return {
    type: types.REMOVE_PRODUCT,
    payload: {
      cartProductId: product.cartProductId,
    },
  };
};

const _removeProduct = (state, {cartProductId}) => {
  let items = [...state.items];
  items = items.filter(i => i.cartProductId !== cartProductId);

  return {...state, items, ...updateTotal(items, state.delivery)};
};

export default function reducer(state = INITIAL_STATE, action) {
  const {type, payload} = action;
  switch (type) {
    case types.SET_DELIVERY:
      return _setDelivery(state, payload);
    case types.SET_PAYMENT:
      return _setPayment(state, payload);
    case types.SET_PRODUCT:
      return _setProduct(state, payload);
    case types.REMOVE_PRODUCT:
      return _removeProduct(state, payload);
    case types.SET_ADDRESS:
      return _setAddress(state, payload);
    case types.SET_CLIENT_NAME:
      return _setClientName(state, payload);
    case types.RESET:
      return _reset(state);
    default:
      return state;
  }
}
